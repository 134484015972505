<div [@fadeTransition] class="article-container">
  <div class="flex flex-col lg:flex-row lg:pt-[51px] pt-[80px]" dir="rtl">
    <div class="article-content">
      <p class="article-content__title xl:-mt-[11px] -mt-[50px]">
        אודות
      </p>
      <p class="article-content__text">כשנפגשנו לפני שלוש שנים, היינו רק קבוצה של יזמים עם חלום. חלום לשנות את הדרך שבה
        מוזיקה מיוצרת, מופצת ומושווקת. חלום להפוך את האמנים למרכז, לתת להם את הכלים להגשים את החלומות שלהם. חלום להפוך
        את המוזיקה למשהו שנוצר באופן אופטימלי, בלי להיות תלוי בלייבלים. </p>

      <p class="article-content__text">החלום הזה התחיל להתממש כאשר הוצאנו את המערכת הראשונה בסדרה של מערכות - Dstro.
        מערכת ההפצה המתקדמת שלנו למוזיקה היא שלב ראשון אך משמעותי במסע שלנו לתת לאמנים כלים טכנולוגיים מתקדמים שילוו
        אותם משלב היצירה ועד לשלבים האחרונים של הפצה וקידום המוזיקה שלהם.</p>

      <p class="article-content__text">אבל אנחנו ממש לא עוצרים שם. המשך המסע שלנו כחברה שנוסדה במטרה לשנות את כללי המשחק
        ולבנות פלטפורמה שמעצימה אמנים כוללת כלים נוספים אשר ילוו אתכם החל משלב היצירה ועד להצלחה של המוזיקה והקריירה
        שלכם. המטרה שלנו ברורה, לאפשר לכם לשמור על העצמאות שאתם רוצים, יחד עם יכולות ברמה של אמנים חתומים ומנוהלים
        בלייבל גדול.</p>

      <p class="article-content__text">הקמנו את החברה, צוות של מפיקי מוזיקה מהמובילים בעולם, אנשי תעשיית המוזיקה, ויזם
        טכנולוגי עם סדרה של הצלחות גדולות וניסיון עשיר בעולם ההייטק. המשקיעים שלנו, קרן Oryzn, קרן Discovery , תום
        ליבנה, שלומית פינקס אלקיים, יסמין לוקץ ורועי ענתבי, הם משקיעים ויזמים אשר הקימו חברות בינלאומיות מצליחות אשר
        מהוות עבורנו השראה, האנשים הללו מלווים אותנו ועומדים מאחורינו במטרה שנהפוך להיות הלייבל האוטונומי, מבוסס בינה
        מלאכותית, הראשון והגדול בעולם.</p>

      <p class="article-content__text">אנחנו, בימים אלה, עובדים סביב השעון כדי להפוך את החלום שלנו למציאות. אנחנו
        ממשיכים לפתח ולשפר את הכלים שלנו, להאזין למשוב של האמנים שלנו, ולהתאים את הטכנולוגיה שלנו לצרכים של התעשייה.
        אנחנו מאמינים שעל ידי עשייה זאת, אנחנו נהפוך לגורם מרכזי בתעשיית המוזיקה העולמית.</p>

      <p class="article-content__text">תזכרו, מה שיש היום, זוהי רק ההתחלה! אנחנו מתרגשים לראות לאן המסע הזה יוביל אותנו,
        ואנחנו מזמינים אתכם להצטרף אלינו. כי בסופו של דבר, אנחנו כאן בשבילכם - האמנים, היוצרים, החולמים. אנחנו כאן כדי
        לעזור לכם להגשים את החלומות שלכם.</p>
    </div>
    <div class="articles">
      <div [routerLink]="'/contact'" class="action cursor-pointer">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">צרו קשר</p>
        <p class="hidden lg:block action--subtitle">יש לך שאלה או מתמודד עם בעיה? צוות התמיכה שלנו כאן כדי לעזור. שלחו
          לנו הודעה!</p>
        <p class="block lg:hidden action--subtitle">יש לך שאלה או מתמודד עם בעיה? <br>צוות התמיכה שלנו כאן כדי לעזור.
          שלחו לנו הודעה!</p>
      </div>
    </div>
  </div>
  <sbz-footer></sbz-footer>
</div>
