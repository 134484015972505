import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {UserStateService} from '../../../services/user-state.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'sbz-distribute',
  templateUrl: './distribute.component.html',
  styleUrl: './distribute.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistributeComponent implements OnInit, OnDestroy {
  buttonText$ = new BehaviorSubject<string>('התחילו להפיץ עכשיו');
  private easterEggOn = false;
  private mCount = 0;

  constructor(private userStateService: UserStateService) {
  }

  ngOnInit() {
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  checkLogin() {
    this.userStateService.checkUserInfoAndNavigate();
  }

  private changeText() {
    this.easterEggOn = !this.easterEggOn;
    this.buttonText$.next(this.easterEggOn ? 'תתחילו להפליץ עכשיו' : 'התחילו להפיץ עכשיו');
  }

  private handleKeyDown(event: KeyboardEvent): void {
    if (event.key.toLowerCase() === 'm') {
      this.mCount++;
      if (this.mCount === 3) {
        this.changeText();
        this.mCount = 0; // Reset count after activation
      }
    } else {
      this.mCount = 0; // Reset count if any other key is pressed
    }
  }
}
