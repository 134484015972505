import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RobotStateService} from '../../services/robot-state.service';
import {UserStateService} from '../../services/user-state.service';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  @Output() onMenuToggle = new EventEmitter<boolean>();
  user$ = this.userStateService.user$;
  isOpen = false;

  constructor(private robot: RobotStateService,
              private userStateService: UserStateService) {
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
    this.onMenuToggle.next(this.isOpen);
  }
}
