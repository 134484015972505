import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {RobotStateService} from '../../services/robot-state.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MouseTrackerService} from '../../services/mouse-tracker.service';
import {bounce} from '../../animations/bounce-animation';
import {LoadingService} from '../../services/loading.service';
import {MobileDetectionService} from '../../services/mobile-detection.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-robot',
  templateUrl: './robot.component.html',
  styleUrl: './robot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [bounce]
})
export class RobotComponent implements AfterViewInit {
  @ViewChild('robotContainer', {static: false, read: ElementRef}) robotContainer: ElementRef | undefined;
  @ViewChild('leftEye', {static: false, read: ElementRef}) leftEye: ElementRef | undefined;
  @ViewChild('rightEye', {static: false, read: ElementRef}) rightEye: ElementRef | undefined;

  isRobotVisible$ = this.robotStateService.isRobotVisible$;
  robotAnimation$ = this.robotStateService.robotAnimation$;
  robotPosition$ = this.robotStateService.robotPosition$;
  robotImageSrc$ = this.robotStateService.robotImageSrc$;
  isLoading$ = this.loadingService.isLoading$;
  shouldBounce$ = this.mobileDetectionService.shouldBounce$;

  constructor(private robotStateService: RobotStateService,
              private mouseTracker: MouseTrackerService,
              private loadingService: LoadingService,
              private mobileDetectionService: MobileDetectionService) {
  }

  ngAfterViewInit() {
    this.mobileDetectionService.isDesktop$
      .subscribe((isDesktop: boolean) => {
        if (isDesktop) {
          this.mouseTracker.mousePosition$.subscribe(({x, y}) => {
            this.onMouseMove(x, y);
          });
        }
      });
  }

  // Cross-eyed version
  private onMouseMove(mouseX: number, mouseY: number) {
    if (!this.robotContainer || !this.leftEye || !this.rightEye) {
      return;
    }

    // For the robot container
    const robot = this.robotContainer.nativeElement;
    // const { left: robotLeft, top: robotTop, width: robotWidth, height: robotHeight } = robot.getBoundingClientRect();
    // const robotCenterX = robotLeft + robotWidth / 2;
    // const robotCenterY = robotTop + robotHeight / 2;
    const robotRect = robot.getBoundingClientRect();
    const robotCenterX = robotRect.left + robotRect.width / 2;
    const robotCenterY = robotRect.top + robotRect.height / 2;

    const sensitivity = 0.01; // Adjust this value based on your needs
    let rotateY = (mouseX - robotCenterX) * sensitivity;
    let rotateX = -(mouseY - robotCenterY) * sensitivity;

    // Limiting rotateY
    const maxRotationDegree = 10;
    rotateY = Math.max(Math.min(rotateY, maxRotationDegree), -maxRotationDegree);
    rotateX = Math.max(Math.min(rotateX, maxRotationDegree), -maxRotationDegree);

    robot.style.transform = `translateX(-50%) perspective(600px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

    // For the eyes
    const eyes = [this.leftEye.nativeElement, this.rightEye.nativeElement];
    eyes.forEach(eye => {
      const {left: eyeLeft, top: eyeTop, width: eyeWidth, height: eyeHeight} = eye.getBoundingClientRect();
      const eyeCenterX = eyeLeft + eyeWidth / 2;
      const eyeCenterY = eyeTop + eyeHeight / 2;

      // Calculate the angle from the eye to the mouse cursor, adjusting for the new robot position
      const eyeDeltaX = mouseX - eyeCenterX;
      const eyeDeltaY = mouseY - eyeCenterY;
      const angle = Math.atan2(eyeDeltaY, eyeDeltaX);

      // Determine how far the eyes should move from the center, respecting boundaries of the eye sockets
      const distance = Math.min(eyeWidth / 4, Math.hypot(eyeDeltaX, eyeDeltaY) / 10);

      // Calculate the new position for the eye
      const eyeX = distance * Math.cos(angle);
      const eyeY = distance * Math.sin(angle);

      // Apply the transformation
      eye.style.transform = `translate(${eyeX}px, ${eyeY}px)`;
    });
  }

  // Both eyes move together version
  // private onMouseMove(mouseX: number, mouseY: number) {
  //   // Assuming the robot's eyes are horizontally aligned and at a similar vertical position
  //   const leftEye = this.leftEye!.nativeElement;
  //   const rightEye = this.rightEye!.nativeElement;
  //
  //   const leftEyeBounds = leftEye.getBoundingClientRect();
  //   const rightEyeBounds = rightEye.getBoundingClientRect();
  //
  //   // Calculate a central point between the two eyes
  //   const centerX = (leftEyeBounds.left + rightEyeBounds.right) / 2;
  //   const centerY = (leftEyeBounds.top + leftEyeBounds.bottom) / 2;
  //
  //   const deltaX = mouseX - centerX;
  //   const deltaY = mouseY - centerY;
  //   const angle = Math.atan2(deltaY, deltaX);
  //
  //   // Determine how far the eyes should move from the center together
  //   const distance = Math.min(leftEye.offsetWidth / 4, Math.hypot(deltaX, deltaY) / 10);
  //
  //   // Calculate the new position for both eyes
  //   const eyeX = distance * Math.cos(angle);
  //   const eyeY = distance * Math.sin(angle);
  //
  //   // Apply the same transformation to both eyes
  //   [leftEye, rightEye].forEach(eye => {
  //     eye.style.transform = `translate(${eyeX}px, ${eyeY}px)`;
  //   });
  // }
}
