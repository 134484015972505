@if (isEmailVerificationModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div [@fadeTransition] class="modal-container verification-modal">
      <div class="x-container">
        <img (click)="closeEmailVerificationModal()" alt="סגור מודל" fill ngSrc="assets/images/close.webp">
      </div>
      <h2 class="modal-header verification-header">הזן את <strong class="font-bold">קוד האימות</strong></h2>
      <p class="modal-content w-[90%]">
        {{
          (isLoading$ | async)
            ? 'שולח קוד אימות...'
            : 'אנא בדוק את האימייל שלך והזן את הקוד שקיבלת כדי לאמת את האימייל שלך'
        }}
      </p>
      <form [formGroup]="verificationForm" class="relative xl:w-[504px] xl:max-w-none lg:w-[450px] w-[80%] max-w[450px]"
            [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}">
        <div class="relative w-full">
          <input class="input-style" formControlName="verificationCode" placeholder="קוד אימות" type="text">
          @if (verificationCodeIsInvalid$ | async) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="assets/images/inputs/input-invalid.webp" alt="שגוי" fill>
              </div>
              <span class="invalid-input">קוד אימות שגוי</span>
            </div>
          }
        </div>
      </form>
      <button (click)="submitVerificationCode()"
              [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}"
              class="main-button-design black-button mx-auto
              xl:!w-[185px] lg:!w-[148px] xl:mt-[37px] lg:mt-[30px] !text-[18px] xl:!h-[61px] lg:!h-[55px]">
        אמת
      </button>
    </div>
  </div>
}