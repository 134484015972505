import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {initFlowbite} from 'flowbite';
import {SubscriptionService} from './services/subscription.service';
import {MobileDetectionService} from './services/mobile-detection.service';

@Component({
  selector: 'sbz-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(private subscriptionService: SubscriptionService,
              private mobileDetectionService: MobileDetectionService) {
  }

  ngOnInit() {
    initFlowbite();

    this.subscriptionService.fetchSubscriptionPackages().then();

    this.mobileDetectionService.startBreakpointObserver();
  }
}
