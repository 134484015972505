<div class="multi-select-container relative">
  <div (click)="toggleDropdown()"
       class="multi-select-field pt-2 pb-2 input-style cursor-pointer flex items-center flex-wrap relative">
    <div class="chevron"></div>
    <span>מה המיקוד המוזיקלי שלך?</span>
  </div>
  @if (dropdownOpen) {
    <div #dropdown class="dropdown-overlay absolute bg-white rounded-lg z-10 w-full">
      <div class="multi-select-dropdown">
        @for (option of options; track $index) {
          <div class="multi-select-option cursor-pointer"
               [class.selected]="isSelected(option)"
               (click)="toggleSelection(option)">
            <input type="checkbox" [checked]="isSelected(option)"/>
            <label class="ml-4">{{ option }}</label>
          </div>
        }
      </div>
    </div>
  }
</div>
