<div [@fadeTransition] class="article-container">
  <div class="flex flex-col lg:flex-row lg:pt-[51px] pt-[80px]" dir="rtl">
    <div class="article-content">
      <p class="article-content__title xl:-mt-[11px] sm:-mt-[30px] -mt-[50px]">
        שאלות נפוצות
      </p>
      <p class="article-content__subtitle">
        כמה עולה השירות?
      </p>
      <p class="article-content__text">השירות ניתן עבור רכישת מנוי שנתי
        <br>
        ניתן לבחור את המסלול המתאים לכם
      </p>

      <p class="article-content__subtitle">
        האם אתם גובים עמלה מעבר לתשלום המנוי?
      </p>
      <p class="article-content__text">אנחנו לא גובים עמלה
        <br>
        כל ההכנסות שמתקבלות עוברות אליכם במלואן
      </p>

      <p class="article-content__subtitle">
        כיצד מעלים שיר?
      </p>
      <p class="article-content__text">בדף התמיכה שלנו באתר תמצאו סרטון הסברה בנושא</p>

      <p class="article-content__subtitle">
        לאילו חנויות אתם מפיצים?
      </p>
      <p class="article-content__text">נכון להיום אנחנו מפיצים ל- 30 חנויות דיגיטליות <br>
        כל החנויות ״החשובות״ והפעילות בישראל נמצאות ברשימה <br>
        הבולטות שבהן: <br>
        ספוטיפיי, אפל מיוזיק, טיקטוק, יוטיוב מיוזיק, אינסטגרם. <br>
        חשוב להדגיש שהרשימה מתעדכנת בהתאם לשינויים בשוק המוזיקה
      </p>

      <p class="article-content__subtitle">
        אם כבר יש לי דף אומן איך אני דואג שהשיר החדש יגיע לשם?
      </p>
      <p class="article-content__text">במהלך יצירת השיר תתבקשו לציין את שם האמן הראשי.<br>
        ניתן להוסיף בעלי תפקידים נוספים כמו אמן אורח או מפיק.<br>
        במידה וקיימים עמודי אמן בספוטיפיי ואפל מיוזיק יש להוסיף את הקישורים אליהם. <br>
        תמצאו לכך הסבר בסרטון, ומצורפת תמונה להמחשה:
      </p>
      <img alt="add artist" class="my-4" height="667" ngSrc="assets/images/help/add_artist.webp" width="651">

      <p class="article-content__subtitle">
        אתם מעלים סאונדים לטיקטוק ולרילס?
      </p>
      <p class="article-content__text">בהחלט כן <br>
        ניתן למצוא בסרטון ההדרכה להעלאת שיר את השלב שבו אתם בוחרים את התזמון (זמן התחלת הסאונד)<br>
        מצורפת תמונה להמחשה:
      </p>
      <img alt="add artist" class="mr-auto my-4" height="109" ngSrc="assets/images/help/add_tiktop.webp" width="616">

      <p class="article-content__subtitle">
        האם ניתן לבחור את תאריך ההפצה?
      </p>
      <p class="article-content__text">ניתן להפיץ באופן מידי או לתזמן את השיר לתאריך שתבחרו<br>
        אנחנו ממליצים תמיד לתזמן מינימום 10 ימים קדימה
      </p>

      <p class="article-content__subtitle">
        איך מקבלים תשלום?
      </p>
      <p class="article-content__text">
        אתם מקבלים דוח בסוף כל חודש שבו תוכלו לראות כמה כסף המוזיקה שלכם עשתה ובאילו פלטפורמות.<br>
        התשלום מתבצע עד לחודש מקבלת הדוח<br>
        התשלום מעובר לחשבון הבנק באמצעות שירות טיפלתי.<br>
        סכום מינימום להעברה הוא 50 דולר
      </p>

      <p class="article-content__subtitle">אילו עוד נתונים ניתן לקבל?</p>
      <p class="article-content__text">נתוני השמעות מהפלטפורמות השונות, מעודכנים וזמינים לצפייה<br>
        כמו כן דוחות חודשיים עם פילוח נתונים מתקדם
      </p>

      <p class="article-content__subtitle">איך גובים תמלוגי יוטיוב?</p>
      <p class="article-content__text">אנחנו גובים עבורכם את תמלוגי היוטיוב<br>
        חשוב להדגיש שאנחנו לא עושים את זה דרך עמוד יוטיוב הרשמי שלכם<br>
        אלא דרך מערכת <strong>YouTube Content ID</strong> שמאשפרת לנו לזהות שימושים בשיר שלכם, ולגבות את התמלוגים עליהם
      </p>

      <p class="article-content__subtitle">איך מסנכרנים את מילות השיר לפלטפורמות השונות?</p>
      <p class="article-content__text">
        סנכרון המילים נעשה דרך תוכנה חיצונית המאפשרת לכם לסנכרן מילים לשירים שלכם ולמעשה לכל אומן שתרצו<br>
        מוזמנים לפתוח חשבון ולהתחיל לסנכרן -
        <a class="underline font-bold" href="https://pro.musixmatch.com/" target="_blank">
          musixmatch
        </a>
      </p>

      <p class="article-content__subtitle">כמה זמן לוקח עד שהשיר שלי מופיע בפלטפורמה הדיגיטלית?</p>
      <p class="article-content__text">
        לכל פלטפורמה לוקח פרק זמן שונה עד שהשיר מופיע בה. יש כאלו שלוקח יומיים וכאלו שלוקח 6 ימים<br>
        במידה ואתם רוצים שהכל יעלה ביחד, אנחנו ממליצים לתזמן את השיר מינימום 10 ימים קדימה<br>
      </p>

      <p class="article-content__subtitle">האם אפשר להעלות קאברים ורמיקסים?</p>
      <p class="article-content__text">
        קאברים ניתן להעלות רק במידה ולא בוצעו שינוים במילים ובלחן וניתנו קרדיטים לכותבים<br>
        רמיקס ניתן להעלות רק במידה ויש אישור מבעלי המאסטר, לפני אישור הסינגל במערכת אתם תתבקשו להציג אישור זה
      </p>

      <p class="article-content__subtitle">מתי ואיך ניתן לפנות לתמיכה ושירות לקוחות?</p>
      <p class="article-content__text">
        נכנסים אל עמוד התמיכה שלנו
        <a class="underline font-bold" routerLink="/contact">פה</a><br>
        ואנחנו זמינים בימים ראשון עד חמישי בין השעות 10:00-17:00<br>
        נשתדל לענות תוך שעתיים
      </p>

      <p class="article-content__subtitle">האם ניתן לשתף פעולה עם הלייבל שלכם?</p>
      <p class="article-content__text">אנחנו כל הזמן מחפשים דברים חדשים ומרגשים<br>
        ואנחנו עוקבים אחרי כל מה שקורה במערכת שלנו<br>
        נדע להגיע אליכם במידת הצורך
      </p>
    </div>
    <div class="articles">
      <div [routerLink]="'/contact'" class="action cursor-pointer">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">צרו קשר</p>
        <p class="action--subtitle">יש לך שאלה או מתמודד עם בעיה? צוות התמיכה שלנו כאן כדי לעזור. שלחו לנו הודעה!</p>
      </div>
    </div>
  </div>
  <sbz-footer></sbz-footer>
</div>
