<div [@fadeTransition] [ngClass]="{'payment-showing': goToPayment$ | async}"
     class="subscription-container">
  @if (goToPayment$ | async) {
    <section class="white-container w-full">
      <p class="text-center font-prop-normal font-bold lg:-mt-[20px] -mt-[36px] xl:text-[38px] lg:text-[30px] text-[19.5px]"
         dir="rtl">
        כמעט סיימנו!
      </p>
      <p class="text-center font-prop-normal font-light mb-[41px] lg:px-0 px-4 xl:text-[20.5px] lg:text-[20px] text-[14px]">
        הזן את פרטי התשלום שלך כדי לנצל את הפוטנציאל המוזיקלי שלך
      </p>
      <img ngSrc="assets/images/credit-cards.webp" alt="credit cards" height="59" width="376"
           class="hidden lg:block mx-auto">
      <img ngSrc="assets/images/credit-cards.webp" alt="credit cards" height="31" width="192"
           class="block lg:hidden mx-auto">
      <form>
        <div class="flex flex-col w-full">
          <div class="w-full px-3 relative xl:mb-[35px] lg:mb-[27px] mb-[19px]">
            <div #cardNumber class="input-style placeholder-[#25293166]"></div>
          </div>

          <!-- Expiration Date and CVV -->
          <div class="flex flex-wrap lg:mb-0 mb-[19px]">
            <div class="w-full lg:w-1/2 px-3 relative mb-[19px]">
              <div #cardExpiry class="input-style placeholder-[#25293166]"></div>
            </div>
            <div class="w-full lg:w-1/2 px-3 relative">
              <div #cardCvc class="input-style placeholder-[#25293166]"></div>
            </div>
          </div>
        </div>
      </form>
    </section>
  } @else {
    <section class="main-packages-container">
      <section class="hidden lg:block main-title-container" dir="rtl">
        <p class="main-text font-prop-normal">בחרו <strong class="font-bold">בחבילה המתאימה</strong> ביותר עבורכם
        </p>
        <button class="main-button-design gradient-button make-absolute subscription-button" (click)="enterCoupon()">
          הכנס קופון
        </button>
      </section>

      <section class="flex lg:hidden main-title-container main-mobile" dir="rtl">
        <p class="main-text font-prop-normal mb-[12px]">בחרו <strong class="font-bold">בחבילה המתאימה</strong> ביותר עבורכם
        </p>
        <button class="main-button-design gradient-button subscription-button xl:!w-[155px] lg:!w-[125px]
            max-w-[310px] sm:max-w-[500px] !w-[80%] !mb-0" (click)="enterCoupon()">הכנס
          קופון
        </button>
      </section>

      <section class="subscription-packages" dir="rtl">
        @for (subscriptionPackage of (subscriptionPackages$ | async); track subscriptionPackage.packageId) {
          <div class="package selected"
               [class.selected]="((existingPackageId$ | async) === subscriptionPackage.userType) || (couponId !== '' && $index === 1)">
            <div class="type">
              <span class="type__user">{{ subscriptionPackage.userType }}</span>
              @if ((existingPackageId$ | async) === subscriptionPackage.userType) {
                <span class="type__chosen" [class.recommended]="subscriptionPackage.isRecommended">החבילה שלי</span>
              }
            </div>

            <div class="total w-full">
              <span class="total__currency">{{ subscriptionPackage.currency }}</span>
              <span class="total__amount" [class.recommended]="subscriptionPackage.isRecommended">
              {{ selectedTier[subscriptionPackage.packageId] || subscriptionPackage.amount }}</span>

              <div class="flex flex-col justify-center items-center w-full">
                <span class="me-auto total__interval">/ לשנה</span>
                @if ((subscriptionPackage.tiers.length || 0) > 1) {
                  <form class="w-full mx-auto flex justify-end items-center !m-0 !p-0 scale-90">
                    <label for="tiers"
                           class="text-sm font-medium text-gray-900 flex-grow">אמנים:</label>
                    <select id="tiers" (change)="chooseTier($event, subscriptionPackage)"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
                             focus:ring-0 focus:border-gray-300 block w-1/2 px-2.5 py-1 scale-90">
                      @for (tier of subscriptionPackage.tiers; track tier; let i = $index) {
                        <option [value]="i">{{ i + 1 }}</option>
                      }
                    </select>
                  </form>
                } @else {
                  <span class="total__credits">{{ subscriptionPackage.totalCredits }}</span>
                }
              </div>
            </div>

            <div class="description">
              <ul>
                @for (line of subscriptionPackage.extraInfo; track line) {
                  <li [ngClass]="{'!font-bold': line.includes('כל מה שיש')}">{{ line }}</li>
                }

              </ul>
            </div>
            <button class="main-button-design black-button subscription-button"
                    [ngClass]="{'gradient-button': subscriptionPackage.isRecommended}"
                    (click)="choosePackage(subscriptionPackage.packageId)">בחרו חבילה
            </button>
          </div>
        }
        <div class="package">
          <div class="type">
            <span class="type__user">חבילת לייבל</span>
          </div>
          <div class="total">
            <span class="total__custom">צרו קשר</span>
          </div>
          <div class="description">
            <span class="description__custom-text">
              <br>
                דברו איתנו ונתאים לכם את החבילה המושלמת עבורכם
              </span>
          </div>
          <button class="main-button-design black-button subscription-button" [routerLink]="'/contact'">צרו קשר
          </button>
        </div>
      </section>
    </section>
  }
</div>

@if (goToPayment$ | async) {
  <div [@fadeTransition] class="w-full flex justify-center xl:mt-[34px] mt-[20px]">
    <div class="flex justify-between xl:px-[60px] xl:w-[845px] lg:w-[700px] lg:px-[20px] w-[90%]"
         dir="rtl">
      <button class="main-button-design black-button xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px]"
              (click)="backToPackages()">אחורה
      </button>
      <button class="main-button-design gradient-button xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px]"
              (click)="onSubmit()">קדימה
      </button>
    </div>
  </div>
}
