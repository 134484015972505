export interface Article {
  id: number;
  link: string;
  title: string;
  description: string;
}

export const articles: Article[] = [
  {
    id: 1,
    title: 'כיצד להעלות שיר חדש',
    description: 'סרטון קצר וממוקד המראה שלב אחר שלב כיצד להעלות שיר חדש להפצה',
    link: 'https://www.youtube.com/watch?v=rKW1O07lMcM'
  },
  {
    id: 2,
    title: 'פתיחת חשבון לתשלום ישיר באמצעות Tipalti',
    description: 'סרטון קצר המראה לכם שלב אחר שלב כיצד לפתוח חשבון תשלום ישיר',
    link: 'https://www.youtube.com/watch?v=OwR7hTuy-Zg'
  },
  {
    id: 3,
    title: 'קבלת הרשאה לדף האמן שלכם בספוטיפיי',
    description: 'צפו בסרטון קצר זה שידריך אתכם שלב אחר שלב כיצד לפתוח עמוד אמן בספוטיפיי',
    link: 'https://www.youtube.com/watch?v=gZWa5rLNRM4'
  },
  {
    id: 4,
    title: 'יצירת פלייליסט בעמוד האמן האישי שלכם',
    description: 'כלי חשוב ועוצמתי, הכנסו למדריך הבא ללמוד כיצד ליצר פלייליסטים בעצמכם בעמוד האמן שלכם.',
    link: 'https://www.youtube.com/watch?v=3MM1ml2NgOY'
  },
  {
    id: 5,
    title: 'המדריך להגשת פיץ׳ לפלטפורמות הסטרימינג',
    description: 'הגשת פיץ׳ מגדילה באופן משמעותי את סיכויי ההצלחה שלכם, למדו כיצד לעשות זאת נכון.',
    link: 'https://www.youtube.com/watch?v=Dwo42-7Xjis'
  },
  {
    id: 6,
    title: 'העלאת קאנבס בספוטיפיי',
    description: 'קאנבס יהפוך את עמוד האמן שלכם למעניין וכזה שיגרום לקהל שלכם להתחבר אליכם. למדו כיצד לעשות זאת',
    link: 'https://www.youtube.com/watch?v=iD0mLVdCmJM'
  }
];
