import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {DescopeAuthModule, DescopeAuthService, descopeInterceptor} from '@descope/angular-sdk';
import {zip} from 'rxjs';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {environment} from '../environments/environment';
import {LoginComponent} from './components/login/login.component';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './components/home/home.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {NgOptimizedImage} from '@angular/common';
import {DistributeComponent} from './components/info-pages/distribute/distribute.component';
import {RobotComponent} from './components/robot/robot.component';
import {OutsideClickDirective} from './directives/outside-click.directive';
import {MenuComponent} from './components/menu/menu.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from './components/register/register.component';
import {DropzoneCdkModule} from '@ngx-dropzone/cdk';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {FooterComponent} from './components/footer/footer.component';
import {ContactUsComponent} from './components/info-pages/contact-us/contact-us.component';
import {HelpAndSupportComponent} from './components/info-pages/help-and-support/help-and-support.component';
import {AboutUsComponent} from './components/info-pages/about-us/about-us.component';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';

import {ReadMoreComponent} from './components/info-pages/articles/read-more/read-more.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';
import {ErrorModalComponent} from './components/modal/error-modal/error-modal.component';
import {FAQComponent} from './components/info-pages/articles/faq/faq.component';
import {CouponModalComponent} from './components/modal/coupon-modal/coupon-modal.component';
import {ProfileComponent} from './components/profile/profile.component';

import {EmailVerificationModalComponent} from './components/modal/email-verification-dialog/email-verification-dialog.component';

export function initializeApp(authService: DescopeAuthService) {
  return () => zip([authService.refreshSession(), authService.refreshUser()]);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    DistributeComponent,
    RobotComponent,
    OutsideClickDirective,
    MenuComponent,
    RegisterComponent,
    FileUploadComponent,
    SubscribeComponent,
    FooterComponent,
    ContactUsComponent,
    HelpAndSupportComponent,
    AboutUsComponent,
    ReadMoreComponent,
    WelcomeComponent,
    SafeHtmlPipe,
    ErrorModalComponent,
    FAQComponent,
    CouponModalComponent,
    ProfileComponent,
    EmailVerificationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DescopeAuthModule.forRoot({
      projectId: environment.descopeProjectId
    }),
    NgOptimizedImage,
    DropzoneCdkModule,
    ReactiveFormsModule,
    FormsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [DescopeAuthService],
      multi: true
    },
    provideHttpClient(withInterceptors([descopeInterceptor])),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
