@if (iframeLink) {
  <div [@fadeTransition] class="fixed inset-0 bg-black bg-opacity-70 z-40" aria-hidden="true"></div>

  <section [@fadeTransition] class="fixed inset-0 flex items-center justify-center z-50">
    <div class="relative bg-black md:w-[768px] md:h-[576px] w-[75%] sm:h-[60%] h-[40%]">
      <!-- Close Icon -->
      <svg (click)="iframeLink = null"
           class="w-10 h-10 text-white cursor-pointer absolute -right-12 top-0 z-50" aria-hidden="true"
           xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
      </svg>
      <!-- Iframe for the video -->
      <iframe (outsideClick)="iframeLink = null" [src]="sanitizedUrl(iframeLink!)" frameborder="0"
              class="absolute inset-0 w-full h-full"
              allowfullscreen></iframe>
    </div>
  </section>
}

<div [@fadeTransition] [class.blur-md]="iframeLink"
     [class.pointer-events-none]="iframeLink" class="flex flex-col items-center" dir="rtl">
  <div class="text-center help-main-title">
    <p class="font-light xl:text-[49.5px] lg:text-[32.5px] sm:text-[42px] text-[19.5px] leading-[1.1]">איך אוכל
      <strong class="font-bold block lg:inline">לעזור לך היום?</strong>
    </p>
  </div>

  <div class="content">
    <div class="actions">
      <div [routerLink]="'/subscribe'" class="action">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/subscription.webp">
        </div>
        <p class="action--title">תוכניות ומחירים</p>
        <p class="action--subtitle">אודות חבילות המנוי השונות, בחרו את המתאימה ביותר עבורכם</p>
      </div>
      <div [routerLink]="'/contact'" class="action">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">צרו קשר</p>
        <p class="action--subtitle">יש לך שאלה או מתמודד עם בעיה? צוות התמיכה שלנו כאן כדי לעזור. שלחו לנו הודעה!</p>
      </div>
      <div [routerLink]="'/articles/faq'" class="action">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/faq.webp">
        </div>
        <p class="action--title">שאלות נפוצות</p>
        <p class="action--subtitle">תשובות לשאלות הנפוצות ביותר שיעזרו לכם להעלות שירים ביתר קלות</p>
      </div>
      <div (click)="downloadPdf()" class="action">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/user.webp">
        </div>
        <p class="action--title">תנאי שימוש</p>
        <p class="action--subtitle">קראו את תנאי השימוש שלנו</p>
      </div>
    </div>

    <p class="articles-title">עזרה ראשונה</p>

    <div class="articles-grid">
      @for (article of articles; track article.id) {
        <div class="article relative cursor-pointer" (click)="iframeLink = article.link">
          <div class="arrow">
            <img fill ngSrc="assets/images/help/arrow-right.webp" alt="arrow">
          </div>
          <p class="article--title">{{ article.title }}</p>
          <p class="article--subtitle">{{ article.description }}</p>
        </div>
      }
    </div>

  </div>

  <sbz-footer></sbz-footer>
</div>
