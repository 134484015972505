import {Injectable} from '@angular/core';
import {DescopeAuthService} from '@descope/angular-sdk';
import {jwtDecode} from 'jwt-decode';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {User} from '../interfaces/user';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LoadingService} from './loading.service';
import {ModalService} from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  userNeedToRegister$ = new BehaviorSubject<boolean>(true);
  user$ = new BehaviorSubject<User | null>(null);
  descopeUser$ = new BehaviorSubject<any | null>(null);
  sessionToken$ = new BehaviorSubject<string | null>(null);
  userNeedToVerifyEmail$ = new BehaviorSubject<boolean>(false);

  constructor(private authService: DescopeAuthService,
              private router: Router,
              private httpClient: HttpClient,
              private loadingService: LoadingService,
              private modalService: ModalService) {
    this.authService.session$.subscribe((session) => {
      this.authService.user$.pipe(
        map(user => user)
      ).subscribe(user => {
        this.descopeUser$.next(user.user);
      });
      if (session.sessionToken) {
        this.updateUserFromDescope(session.sessionToken);
      }
    });
  }

  updateUserFromDescope(sessionToken: string, pictureUrl: string | null = null) {
    this.sessionToken$.next(sessionToken);
    const decodedJwt: any = jwtDecode(sessionToken);
    const verifiedEmail = this.descopeUser$.getValue()?.verifiedEmail;

    const userData = {
      ...decodedJwt,
      ...this.descopeUser$.getValue(),
      name: decodedJwt.displayName || this.descopeUser$.getValue()?.name,
      pictureUrl: decodedJwt.pictureUrl || this.descopeUser$.getValue()?.picture,
      roles: decodedJwt.roles || this.descopeUser$.getValue()?.roleNames,
      phone: decodedJwt.phone || this.descopeUser$.getValue()?.phone,
      userHasSubscribed: decodedJwt.userHasSubscribed || this.descopeUser$.getValue()?.userHasSubscribed || this.descopeUser$.getValue()?.customAttributes?.userHasSubscribed,
      customAttributes: {
        ...this.descopeUser$.getValue()?.customAttributes,
        isWhiteList: false,
        finishedRegistration: decodedJwt.finishedRegistration || this.descopeUser$.getValue()?.finishedRegistration || this.descopeUser$.getValue()?.customAttributes?.finishedRegistration,
        pendingVerification: decodedJwt.pendingVerification || this.descopeUser$.getValue()?.customAttributes?.pendingVerification,
        customEmail: this.descopeUser$.getValue()?.customAttributes?.customEmail,
      },
    };

    // this.user$.next({
    //   loginId: this.descopeUser$.getValue()?.loginIds[0],
    //   email: userData?.customAttributes?.customEmail || userData.email,
    //   phone: userData.phone,
    //   displayName: userData.name,
    //   youtubeChannel: userData.youtubeChannel,
    //   instagramMusicProfile: userData.instagramMusicProfile,
    //   spotifyProfileLink: userData.spotifyProfileLink,
    //   appleMusicProfile: userData.appleMusicProfile,
    //   origin: userData.customAttributes.origin,
    //   pictureUrl: pictureUrl ? pictureUrl : userData.pictureUrl,
    //   finishedRegistration: userData.customAttributes.finishedRegistration,
    //   token: userData.sub,
    //   userHasSubscribed: userData.userHasSubscribed,
    //   selectedPackageId: userData.selectedPackageId,
    //   isWhiteList: userData.customAttributes.isWhiteList,
    //   roles: userData.roles,
    //   userRoles: userData.userRoles,
    //   verifiedEmail,
    // });

    this.user$.next({
      loginId: this.descopeUser$.getValue()?.loginIds[0],
      email: userData?.customAttributes?.customEmail || userData.email,
      phone: userData.phone,
      displayName: userData.name,

      youtubeChannel: userData.youtubeChannel,
      instagramMusicProfile: userData.instagramMusicProfile,
      spotifyProfileLink: userData.spotifyProfileLink,
      appleMusicProfile: userData.appleMusicProfile,
      origin: userData.customAttributes.origin,

      pictureUrl: pictureUrl ? pictureUrl : userData.pictureUrl,
      finishedRegistration: userData.customAttributes.finishedRegistration,
      token: userData.sub,
      userHasSubscribed: userData.userHasSubscribed,
      selectedPackageId: userData.selectedPackageId,
      customAttributes: userData.customAttributes,
      roles: userData.roles,
      userRoles: userData.userRoles,
      role: decodedJwt.role,
      verifiedEmail,
    });
    // const userNeedToRegister = !decodedJwt.email || !decodedJwt.phone || !decodedJwt.finishedRegistration;
    // this.userNeedToRegister$.next(userNeedToRegister);

    if ((!(userData.email || userData.customAttributes.customEmail) || userData.customAttributes.pendingVerification)) {
      this.userNeedToVerifyEmail$.next(true);
    }

    let userNeedToRegister = !userData.email || !userData.phone || !userData.customAttributes.finishedRegistration;
    if (userData.customAttributes.finishedRegistration) {
      userNeedToRegister = false;
    }

    this.userNeedToRegister$.next(userNeedToRegister);

    if (localStorage.getItem('t')) {
      console.log('t: ', localStorage.getItem('t'));
      return;
    }

    const url = location.href;
    if (url.includes('verify-email')) {
      return;
    }

    if (!userNeedToRegister) {
      if (!decodedJwt.userHasSubscribed) {
        if (location.pathname !== '/subscribe') {
          this.router.navigate(['/subscribe']).then();
        }
      } else {
        this.router.navigate(['/']).then();
      }
    }
  }

  updateUserFromRegister(user: User) {
    this.user$.next(user);
    this.userNeedToRegister$.next(false);
  }

  logoUt() {
    this.authService.descopeSdk.logout();
    this.user$.next(null);
  }

  checkUserInfoAndNavigate() {
    this.loadingService.toggleLoading(true);
    const userNeedToRegister = this.userNeedToRegister$.getValue();
    const user = this.user$.getValue();

    if (user?.userHasSubscribed) {
      this.goToRevelator();
      return;
    }

    this.loadingService.toggleLoading(false);
    if (userNeedToRegister || !user) {
      this.router.navigate(['/login']).then();
      return;
    }

    this.router.navigate(['/subscribe']).then();
  }

  updateUserInfo(user: User) {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${user.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, user, options)
      .subscribe({
        next: () => {
          this.updateUserFromRegister(user);
          this.router.navigate(['subscribe']).then();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  updateUserInformation(data: any) {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/${this.user$.getValue()!.token}`;
    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.put(url, data, options)
      .subscribe({
        next: () => {
          const user = this.user$.getValue();
          if (user) {
            this.updateUserFromRegister({
              ...user,
              phone: data.userPhone,
              role: data.userRole,
            });
          }
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  
  }

  goToRevelator() {
    const url = `${environment.baseUrl}/users/revelator-user`;
    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };
    this.httpClient.get(url, options)
      .subscribe({
        next: (data: any) => {
          window.location.href = data.revelatorToken.redirectUrl;
        },
        error: (error) => {
          this.modalService.openErrorModal('We could not find a link to revelator. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      });
  }

  updateSubscriptionStatus(packageId: string) {
    const user = this.user$.getValue();
    if (user) {
      user.userHasSubscribed = true;
      user.selectedPackageId = packageId;
    }
  }

  loginWithEmail(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.signIn(email, password)
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Login error: ', error);
            observer.error(error);
          }
        });
    });
  }

  updateUserMetadata() {
    const url = `${environment.baseUrl}/users/location/${this.user$.getValue()?.token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    this.httpClient.patch(url, {}, options)
      .subscribe({
        next: () => {
          this.authService.refreshSession();
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not update user info. Please try again later.');
        },
        complete: () => {
          // this.loadingService.toggleLoading(false);
        }
      });
  }

  registerWithEmail(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.signUp(
        email,
        password,
        {email},
        // {
        //   templateOptions: {
        //     subject: 'Verify Your Email for Our App',
        //     text: 'Click the link below to verify your email for Our App:',
        //     domain: location.origin,
        //   }
        // }
      )
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Registration error: ', error);
            observer.error(error);
          }
        });
    });
  }

  updatePassword(email: string, password: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.update(email, password)
        .subscribe({
          next: (response) => {
            console.log('updatePassword response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('Update password error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendMfaCodeToEmail(loginId: string, email: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.otp.update.email(loginId, email, undefined, {
        templateOptions: {
          domain: location.origin,
          'deviceOS': 'Value',
          'startHostName': 'Value2',
          email: encodeURIComponent(email),
          project: 'DSTRO',
        }
      })
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('MFA code error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendMfaCodeToRegisteredEmail(email: string): Observable<any> {
    return new Observable((observer) => {
      // this.authService.descopeSdk.otp.signIn.email(email)
      this.authService.descopeSdk.password.sendReset(
        email,
        location.origin + '/login',
        {
          redirectUrl: location.origin + '/login',
          'deviceOS': 'Value',
          'startHostName': 'Value2',
          email: encodeURIComponent(email),
          project: 'DSTRO',
        })
        .subscribe({
          next: (response) => {
            console.log('response: ', response);
            if (!response.ok) {
              observer.error(response);
              return response;
            }
            observer.next(response);
            return response;
          },
          error: (error) => {
            console.error('MFA code error: ', error);
            observer.error(error);
          }
        });
    });
  }

  sendPasswordResetEmail(email: string): Observable<any> {
    return new Observable((observer) => {
      this.authService.descopeSdk.password.sendReset(email)
        .subscribe({
          next: (response) => {
            alert('Password reset email sent! Please check your inbox.');
          },
          error: (error: any) => {
            console.error('Error sending password reset email:', error);
            alert('Failed to send password reset email.');
          }
        });
    });
  }

  safeEncodeURIComponent(value: string): string {
    try {
        // Decode first; if it throws, the value wasn't encoded
        return value === decodeURIComponent(value) ? encodeURIComponent(value) : value;
    } catch (e) {
        return encodeURIComponent(value);
    }
  }

  verifyEmailCode(loginId: string, code: string): Observable<any> {

    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/verify/confirm-email?loginId=${this.safeEncodeURIComponent(loginId)}&code=${code}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.post(url, {}, options).pipe(
      tap((response) => {
        console.log('Response:', response);
        // Optionally perform additional actions, such as updating the user
        // localStorage.setItem('userAcceptedEula', 'true');
        // this.authService.refreshSession();
        // @ts-ignore
        // this.user$.next({...this.user$.getValue(), userAcceptedEula: true});
      }),
      catchError((err: any) => {
        console.error('Error:', err);
        // this.modalService.openErrorModal('We could not update user info. Please try again later.');
        return err; // Ensure error is re-thrown after being handled
      }),
      finalize(() => {
        this.loadingService.toggleLoading(false); // Ensure loading is turned off
      })
    );
    // return this.authService.descopeSdk.otp.verify.email(loginId, code);
  }

  verifyMfaCode(email: string, code: string): Observable<any> {
    return this.authService.descopeSdk.otp.verify.email(email, code);
  }

  verifyMagicLink(code: string): Observable<any> {
    const data = this.authService.descopeSdk.magicLink.verify(code);
    console.log('data: ', data);

    return data;
  }

  loginWithFacebook() {
    this.authService.descopeSdk.oauth.start('facebook', location.origin + '/login').subscribe({
      next: (response) => {
        window.location.href = response?.data?.['url'];
      },
      error: (error) => {
        console.error('error: ', error);
      }
    });
  }

  loginWithGoogle() {
    return this.authService.descopeSdk.oauth.start('google', location.origin + '/login').subscribe({
      next: (response) => {
        window.location.href = response?.data?.['url'];
      },
      error: (error) => {
        console.error('error: ', error);
      }
    });
  }

  verifyNewEmail(email: string, token: string): Observable<any> {
    this.loadingService.toggleLoading(true);
    const url = `${environment.baseUrl}/users/mail/${token}`;

    const options = {
      headers: {'Authorization': `Bearer ${this.sessionToken$.getValue()}`}
    };

    return this.httpClient.patch(url, { email }, options).pipe(
      tap({
        next: () => {
          this.loadingService.toggleLoading(false);
        },
        error: (err: any) => {
          this.modalService.openErrorModal('We could not verify your email. Please try again later.');
        },
        complete: () => {
          this.loadingService.toggleLoading(false);
        }
      })
    );
  }

  handleOAuthResponse(code: string) {
    return new Promise((resolve, reject) => {
      this.authService.descopeSdk.oauth.exchange(code).subscribe({
        next: (response) => {
          console.log('handleOAuthResponse response: ', response);
          if (!response.ok) {
            console.error('Error during OAuth response handling:', response);
            reject(response);
          }
          resolve(response);
        },
        error: (error) => {
          console.error('Error during OAuth response handling:', error);
          reject(error);
        }
      });
    });
  }
}
