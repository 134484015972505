<div [@fadeTransition] class="flex flex-col items-center h-full">
  <div [@fadeTransition] class="flex flex-col items-center" dir="rtl">
    <div class="text-center xl:mt-[53px] lg:mt-[66px] mt-[20px]">
      <p class="font-light xl:text-[49.5px] lg:text-[39.5px] sm:text-[72px] text-[26px]"><strong class="font-bold">ברוכים
        הבאים!</strong></p>
      <p class="font-medium xl:text-[22.5px]  sm:text-[28px] lg:text-[18px] sm:px-8 lg:px-0 lg:mt-0 text-[16px] px-4 mt-4">
        אנחנו כאן כדי להחזיר
        את הכוח לידיים של אמנים, עם כלים רבי
        עוצמה<br>
        שיכולים לשנות את
        מסלול הקריירה המוזיקלית שלך. שליטה במוזיקה שלך, בדרך שלך!</p>
      <button (click)="checkLogin()" class="main-button-design gradient-button xl:mt-[37px] lg:mt-[30px] mt-[23px]">
        התחילו להפיץ עכשיו
      </button>
    </div>
  </div>
</div>
