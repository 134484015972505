<div [@fadeTransition] class="flex flex-col items-center">
  <div class="main-text">
    <p class="main-text__header"><strong
        class="font-bold family-poppins">מערכת הפצה מוזיקלית לאמנים עצמאיים
    </strong>
    </p>
    <p class="main-text__subtext">
      שחררו את המוזיקה שלכם על גבי מערכת עם גב של לייבל ותמיכה של קהילה
    </p>
    <button id="login-1" (click)="checkLogin()" class="main-button-design gradient-button lg:mt-[37px] mt-[42px]">
      {{ buttonText$ | async }}
    </button>
  </div>

  <div class="first-image-container">
    <img alt="Image distribute 1" fill ngSrc="../../../../assets/images/distribute/img-1.webp" priority>
  </div>

  <div class="section-container justify-between xl:mt-[110px] mt-[65px]">
    <div class="section-container--text text-one">
      <div class="distribute-item-title">
        <h2 class="lg:-mb-[12px]">תמיכה מלאה בעברית</h2>
        <strong class="font-bold">וממשק משתמש פשוט לשימוש</strong>
      </div>
      <p class="distribute-item-text">מערכת ההפצה שלנו משלבת גמישות ופשטות.
        באמצעות עיצוב ידידותי תהליך ההפצה של שיר הופך לפשוט ביותר.
        עבור המשתמשים שלנו בארץ, הממשק כולו בעברית אולם יותר מכך,
        תוכלו להינות מצוות תמיכה ישראלי, מקומי, הממוקם בארץ ופועל בשעות שלכם. </p>
      <button id="login-2" (click)="checkLogin()" class="main-button-design gradient-button">
        {{ buttonText$ | async }}
      </button>
    </div>

    <div class="second-image-container">
      <img alt="Image distribute 2" fill ngSrc="../../../../assets/images/distribute/img-2.webp">
    </div>
  </div>

  <div class="section-container">
    <div class="hidden lg:block third-image-container">
      <img alt="Image distribute 3" fill ngSrc="../../../../assets/images/distribute/img-3.webp">
    </div>

    <div class="section-container--text text-two">
      <div class="distribute-item-title">
        <h2 class="lg:-mb-[12px]">העצמאות שאתם רוצים</h2>
        <strong class="font-bold">עם עוצמה ויכולות של לייבל </strong>
      </div>
      <p class="distribute-item-text">באמצעות יכולות ניהול מתקדמות, חוזים חכמים, ניהול
        תשלומים,
        מקדמות וכלי ניתוח וקבלת החלטות, לצד צוות מקומי,
        תקבלו שירות שעד כה היה שייך לאמנים מנוהלים בלבד.</p>
      <button id="login-3" (click)="checkLogin()" class="main-button-design gradient-button">
        {{ buttonText$ | async }}
      </button>
    </div>

    <div class="lg:hidden third-image-container">
      <img alt="Image distribute 3" fill ngSrc="../../../../assets/images/distribute/img-3.webp">
    </div>
  </div>

  <div class="section-container xl:mt-[90px] justify-between">
    <div class="section-container--text text-three">
      <div class="distribute-item-title">
        <h2 class="lg:-mb-[12px]">כלים אנליטיים</h2>
        <strong class="font-bold">למקסימום סיכויי הצלחה</strong>
      </div>
      <p class="distribute-item-text">
        באמצעות כלים אנליטיים מתקדמים תקבלו תובנות ייחודיות אודות הקהל שלכם בנוסף למגמות המעודכנות של עולם המוזיקה.
        מעכשיו תוכלו לקבל החלטות שישפיעו לא רק על המוזיקה אלא על הקריירה שלכם!
      </p>
      <button id="login-4" (click)="checkLogin()" class="main-button-design gradient-button">
        {{ buttonText$ | async }}
      </button>
    </div>

    <div class="fourth-image-container">
      <img alt="Image distribute 4" fill ngSrc="../../../../assets/images/distribute/img-4.webp">
    </div>
  </div>

  <div class="section-container justify-between gap-4">
    <div class="flex flex-col items-center justify-center w-full text-center mx-auto">
      <h2 class="distribute-item-title !block !text-center xl:!mb-[53px] lg:!mb-[36px]">נסו את
        <strong class="font-bold">מערכת ההפצה שלנו</strong> עכשיו!
      </h2>
      <button id="login-5" (click)="checkLogin()" class="main-button-design gradient-button !self-center">
        {{ buttonText$ | async }}
      </button>
    </div>
  </div>

  <sbz-footer></sbz-footer>
</div>

