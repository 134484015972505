<p class="text-center text-[#252931] title" dir="rtl">משתמש חדש? <strong>בואו נרשם</strong></p>
<div class="stepper-container hidden lg:flex" dir="rtl">
  <div [ngClass]="{'completed-step': activeStep > 1, 'active-step': activeStep === 1}" class="step">
    <span class="step--badge">1</span>
    <span class="step--text">פרטים אישיים</span>
  </div>
  <span [ngClass]="{'active-splitter': activeStep > 1}" class="line-splitter"></span>
  <div [ngClass]="{'completed-step': activeStep > 2, 'active-step': activeStep === 2}" class="step">
    <span class="step--badge">2</span>
    <span class="step--text">נוכחות חברתית</span>
  </div>
  <span [ngClass]="{'active-splitter': activeStep > 2}" class="line-splitter"></span>
  <div [ngClass]="{'active-step': activeStep === 3}" class="step">
    <span class="step--badge">3</span>
    <span class="step--text">תמונת פרופיל ותיאור</span>
  </div>
</div>

<div class="stepper-container flex lg:hidden" dir="rtl">
  <div class="step active-step">
    @switch (activeStep) {
      @case (1) {
        <span class="step--badge">1</span>
        <span class="step--text">פרטים אישיים</span>
      }
      @case (2) {
        <span class="step--badge">2</span>
        <span class="step--text">נוכחות חברתית</span>
      }
      @case (3) {
        <span class="step--badge">3</span>
        <span class="step--text">תמונת פרופיל ותיאור</span>
      }
    }
  </div>
</div>

<form (ngSubmit)="onSubmit()" [formGroup]="form"
      [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}"
      dir="rtl">
  @switch (activeStep) {
    @case (1) {
      <div class="reg-input-container">
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="text" placeholder="שם מלא" formControlName="fullName"
                 class="input-style placeholder-[#25293166]">
          @if (getErrorForField('fullName')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נדרש שם מלא</span>
            </div>
          }
        </div>
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="text" placeholder="מספר פלאפון" formControlName="phone"
                 class="input-style placeholder-[#25293166]">
          @if (getErrorForField('phone')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נדרש מספר טלפון תקף</span>
            </div>
          }
        </div>
      </div>
      <div class="reg-input-container mb-[13px] lg:mb-0">
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="email" placeholder="אימייל" formControlName="email" class="input-style placeholder-[#25293166]">
          @if (getErrorForField('email')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נדרש אימייל חוקי</span>
            </div>
          }
        </div>
        <div class="active-one-container">
          <select formControlName="role" class="input-style !pr-[1.45rem] sm:!pr-[3.45rem] lg:!pr-[1.55rem]">
            <option value="">בחר את התפקיד שלך</option>
            <option value="Artist">אמן</option>
            <option value="Artist Manager">מנהל אמנים</option>
            <option value="Distributor">לייבל</option>
          </select>
          @if (getErrorForField('role')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נדרש תפקיד</span>
            </div>
          }
        </div>
      </div>
    }
    @case (2) {
      <!-- First Line of Inputs -->
      <div class="reg-input-container justify-between">
        <!-- Youtube Channel Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-youtube.webp" alt="YouTube"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="ערוץ יוטיוב" formControlName="youtubeChannel"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          @if (getErrorForField('youtubeChannel')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נא להזין קישור חוקי</span>
            </div>
          }
        </div>
        <!-- Instagram URL Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-instagram.webp" alt="Instagram"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="כתובת אינסטגרם" formControlName="instagramUrl"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          @if (getErrorForField('instagramUrl')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נא להזין קישור חוקי</span>
            </div>
          }
        </div>
      </div>

      <!-- Second Line of Inputs -->
      <div class="reg-input-container justify-between mb-[13px] lg:mb-0">
        <!-- Spotify Artist Page Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-spotify.webp" alt="Spotify"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="עמוד אמן בספוטיפיי" formControlName="spotifyArtistPage"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          @if (getErrorForField('spotifyArtistPage')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נא להזין קישור חוקי</span>
            </div>
          }
        </div>
        <!-- Apple Music Profile Input -->
        <div class="active-two-container">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-applemusic.webp" alt="Apple Music"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="פרופיל אפל מיוזיק" formControlName="appleMusicProfile"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          @if (getErrorForField('appleMusicProfile')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נא להזין קישור חוקי</span>
            </div>
          }
        </div>
      </div>
    }
    @case (3) {
      <div class="reg-input-container justify-between">
        <div class="mb-6 px-2 lg:w-[168px] lg:me-[25px] w-full">
          <sbz-file-upload fileType="image/*" [userImage]="userImage"
                           (base64ImageCreated)="base64ImageCreated($event)"></sbz-file-upload>
        </div>
        <div class="mb-6 px-2 lg:w-[535px] w-full">
           <textarea placeholder="ספר לנו משהו על עצמך..." formControlName="description"
                     class="input-style placeholder-[#25293166] lg:!h-[155px] sm:!h-[371px] !h-[172px] sm:pt-[40px] lg:pt-0"
                     style="resize: none;"></textarea>
        </div>
      </div>
    }
  }
</form>
