@if (isCouponModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div [@fadeTransition] class="modal-container coupon-modal"
      dir="rtl">
      <div class="x-container">
        <img (click)="closeModal()" alt="close modal" fill ngSrc="assets/images/close.webp">
      </div>
      @if (subscriptionPackageFound) {
        <h2 class="modal-header coupon-header"><strong class="font-bold">קופון הופעל</strong></h2>
        <div class="modal-content w-[90%]">
          <div class="circle-container">
            <div class="circle">
            </div>
            <div class="w-[25px] h-[20px] absolute m-auto left-0 right-0">
              <img fill ngSrc="assets/images/check.webp" alt="check mark">
            </div>
          </div>
          <p class="mt-8">
            מימשת קוד ל<strong>חבילה אישית ומקצועית</strong>.<br>
            לחץ אישור לראות את החבילה החדשה שלך
          </p>
        </div>
      } @else {
        <h2 class="modal-header coupon-header">הכנס את <strong class="font-bold">קוד הקופון</strong>
      </h2>
      <p class="modal-content w-[90%]">
        {{
        (isLoading$ | async)
        ? 'קופון בבדיקה'
        : 'הקלד את קוד הקופון שלך כאן כדי לראות את פרטי החבילה החדשים'
        }}
      </p>
      <form [formGroup]="couponForm" class="relative xl:w-[504px] xl:max-w-none lg:w-[450px] w-[80%] max-w[450px]"
        [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}">
        <div class="relative w-full">
          <input class="input-style" formControlName="couponCode" placeholder="קוד קופון" type="text">
          @if (couponIsInvalid$ | async) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">קוד קופון לא תקין</span>
            </div>
          }
        </div>
      </form>
    }
    <button (click)="checkCouponOrClose()"
      [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}"
            class="main-button-design black-button mx-auto
              xl:!w-[185px] lg:!w-[148px] xl:mt-[37px] lg:mt-[30px] !text-[18px] xl:!h-[61px] lg:!h-[55px]">
      אישור
    </button>
  </div>
</div>
}
