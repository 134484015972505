@if (modalService.isErrorModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container error-modal" dir="rtl">
      <h2 class="modal-header">התרחשה <strong class="font-bold">שגיאה</strong></h2>
      <p [innerHTML]="modalService.errorMessage | SafeHtml" class="modal-content"></p>
      <p class="modal-content -mb-[4rem]">אם השגיאה ממשיכה,<br>
        <a (click)="modalService.closeErrorModal()" [routerLink]="'/contact'"
        class="font-bold cursor-pointer underline">לחץ כאן</a>
      ליצור איתנו קשר</p>
      <button (click)="modalService.closeErrorModal()"
        class="main-button-design black-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px] !bg-[#d92525]">
        אישור
      </button>
    </div>
  </div>
}
