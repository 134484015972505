import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../../animations/fade-in-animation';

@Component({
  selector: 'sbz-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FAQComponent {
}
