<footer [@fadeTransition] dir="rtl">
  <div class="session-small-logo">
    <img [routerLink]="'/'" alt="Image distribute 1" class="cursor-pointer" fill
         ngSrc="../../../assets/images/footer/small_logo.webp" priority>
  </div>
  <div class="socials-links">
    <a [routerLink]="'/help'">תמיכה</a>
    <a [routerLink]="'/contact'">צרו קשר</a>
    <a [routerLink]="'/about'">אודות</a>
    <a [routerLink]="'/'">בית</a>
  </div>
</footer>
