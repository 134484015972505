<div [@fadeTransition] class="contact-container"
     [ngClass]="{'message-sent': messageSent$ | async}">
  <section class="flex-grow flex">
    <div class="flex flex-col w-full">
      @if (messageSent$ | async) {
        <section [@fadeTransition] class="sent-container">
          <h2 class="main-title">הודעה
            <strong class="font-bold">נשלחה!</strong></h2>
          <p class="hidden lg:block sub-text">תודה שפנית אלינו! נחזור אליך בהקדם.</p>
          <p class="block lg:hidden sub-text">תודה שפנית אלינו!<br>נחזור אליך בהקדם</p>
        </section>
      } @else {
        <section class="send-container" dir="rtl">
          <h2 class="main-title">צרו
            <strong class="font-bold">עימנו קשר!</strong></h2>
          <p class="sub-text">שלחו לנו הודעה ונחזור אליכם!</p>

          <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="form-container">
              <div class="form-container__name-email">
                <div class="contact-input lg:mb-0 sm:mb-[40px] mb-[18px]">
                  <input type="text" placeholder="שם מלא" formControlName="name"
                         class="input-style placeholder-[#25293166]">
                  @if (getErrorForField('name')) {
                    <div class="invalid-input-container">
                      <img src="assets/images/inputs/input-invalid.webp" alt="invalid" class="w-[14px] h-[13px]">
                      <span class="invalid-input">נדרש שם מלא</span>
                    </div>
                  }
                </div>

                <div class="contact-input">
                  <input type="email" placeholder="אימייל" formControlName="email"
                         class="input-style placeholder-[#25293166]">
                  @if (getErrorForField('email')) {
                    <div class="invalid-input-container">
                      <img src="assets/images/inputs/input-invalid.webp" alt="invalid" class="w-[14px] h-[13px]">
                      <span class="invalid-input">נדרש אימייל חוקי</span>
                    </div>
                  }
                </div>
              </div>

              <div class="form-container__msg">
                <input placeholder="הודעה" formControlName="message"
                       class="input-style placeholder-[#25293166]">
                @if (getErrorForField('message')) {
                  <div class="invalid-input-container">
                    <img src="assets/images/inputs/input-invalid.webp" alt="invalid" class="w-[14px] h-[13px]">
                    <span class="invalid-input">עליך להזין הודעה</span>
                  </div>
                }
              </div>

              <div class="form-container__button">
                <button class="main-button-design gradient-button lg:!w-[25%] !w-full" type="submit">
                  שלח
                </button>
              </div>
            </div>
          </form>
        </section>
      }
    </div>

    <div class="image-container">
      <img alt="Image distribute 2" fill ngSrc="assets/images/contact-us/contact.webp" priority
           srcset="assets/images/contact-us/contact@2x.webp 2x,
                   assets/images/contact-us/contact@3x.webp 3x">
    </div>
  </section>

  <sbz-footer class="lg:hidden !mt-[74px]"></sbz-footer>
</div>
<sbz-footer class="lg:bottom-0 lg:block hidden !mt-[40px]"></sbz-footer>
