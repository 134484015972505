import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class ContentLocationService {
  currentBreakpoint$ = new BehaviorSubject<string>('');
  private breakpoints = {
    mb: 150, // min-width
    sm: 768, // min-width,
    md: 1024, // min-width
    lg: 1280, // min-width
    xl: 1440, // min-width
    '2xl': 1536, // min-width
    '3xl': 1920 // min-width
  };

  constructor(private breakpointObserver: BreakpointObserver) {
    this.detectBreakpoint();
    this.watchWindowSize();
  }

  private detectBreakpoint() {
    const width = window.innerWidth;
    // Reverse the order to match the largest possible breakpoint first
    const breakpointKeys = Object.keys(this.breakpoints).reverse() as Array<keyof typeof this.breakpoints>;
    const foundBreakpoint =
      breakpointKeys.find(key => width >= this.breakpoints[key]) || 'sm'; // Default to 'sm' if none match
    this.currentBreakpoint$.next(foundBreakpoint);
  }

  private watchWindowSize() {
    this.breakpointObserver.observe([
      `(min-width: ${this.breakpoints.mb}px)`,
      `(min-width: ${this.breakpoints.sm}px)`,
      `(min-width: ${this.breakpoints.md}px)`,
      `(min-width: ${this.breakpoints.lg}px)`,
      `(min-width: ${this.breakpoints.xl}px)`,
      `(min-width: ${this.breakpoints['2xl']}px)`,
      `(min-width: ${this.breakpoints['3xl']}px)`
    ]).subscribe((state: BreakpointState) => {
      this.detectBreakpoint();
    });
  }

  private getValueAfterBreakPointsChanges(value: number, valueToSubtract = 0): number {
    const absoluteValue = Math.abs(value);
    const currentBreakpoint = this.currentBreakpoint$.getValue();
    if (valueToSubtract === 0) {
      switch (currentBreakpoint) {
        case 'mb':
          valueToSubtract = 180;
          break;
        case 'sm':
          valueToSubtract = 150;
          break;
        case 'md':
          valueToSubtract = 110;
          break;
        case 'lg':
          valueToSubtract = 70;
          break;
        case 'xl':
        case '2xl':
        case '3xl':
        default:
          break;
      }
    } else {
      switch (currentBreakpoint) {
        case 'mb':
          valueToSubtract = valueToSubtract + 80;
          break;
        case 'sm':
          valueToSubtract = valueToSubtract + 50;
          break;
        case 'md':
          valueToSubtract = valueToSubtract + 10;
          break;
        case 'lg':
        case 'xl':
        case '2xl':
        case '3xl':
        default:
          break;
      }
    }

    return absoluteValue - valueToSubtract; // Subtract 60 from the absolute value
  }
}
