<div [@fadeTransition] class="flex flex-col items-center">
  <div class="text-center xl:mt-[53px] lg:mt-[66px] mt-[20px]">
    <p class="main-title"><strong class="font-bold">ברוכים הבאים</strong></p>
    <p class="sub-title" dir="rtl">
      אנחנו כאן כדי להחזיר את הכוח לידיים של האמנים באמצעות טכנולוגיות מתקדמות<br>
      ובינה מלאכותית כדי לסייע בניהול הקריירה המוזיקלית שלכם!</p>
    <button (click)="checkLogin()" class="main-button-design gradient-button 3xl:mt-[64px] lg:mt-[51px] mt-[31px]">
      התחילו להפיץ עכשיו
    </button>
  </div>

  <div class="vision-container">
    <div class="eye-image">
      <img alt="Image distribute 1" fill ngSrc="/assets/images/about-us/eye.webp" priority>
    </div>
    <p class="main-title !text-white 3xl:mt-[66px] lg:mt-[53px] mt-[19px]">
      <strong class="font-bold">אודות</strong>
    </p>
    <p class="about-us-text" dir="rtl">
      אנחנו מוזיקאים ואנשי טכנולוגיה העובדים יחד כדי לשנות את תעשיית המוזיקה באמצעות שימוש בבינה מלאכותית וטכנולוגיות
      מתקדמות.
      הכוונה שלנו אינה שינוי של כללי המשחק, אלא יצירה של כללים חדשים לחלוטין.
      המשימה שלנו היא לעצב מחדש את תעשיית המוזיקה, לשחרר אמנים מהצורך להיכנס לחוזים מגבילים,
      ולצד זאת לתת לאמנים את העוצמה שהם צריכים כדי להצליח...
    </p>

    <button class="read-more-text" routerLink="/articles/read-more">
      המשך לקרוא
    </button>
  </div>

  <div class="team-container">
    <div class="team-image">
      <img alt="Image distribute 1" fill ngSrc="/assets/images/about-us/team.webp" priority>
    </div>

    <p class="main-title 3xl:mt-[74px] lg:mt-[58px] mt-[8px]">
      <strong class="font-bold">הצוות</strong>
    </p>
    <p class="sub-title text-center" dir="rtl">
      את Dstro הקמנו יחד, צוות של מפיקים מוזיקליים, לצד ותיקי תעשיית המוזיקה ויזם הייטק, עם מטרה אחרת וברורה:<br>
      לתת לכם, האמנים העצמאיים, מערכת ייחודית שמצד אחד משמרת לכם את החופש אותו<br>
      אתם רוצים, לצד יכולות מתקדמות שעד כה היו נחלתם הבלעדית של אמנים מנוהלים ולייבלים.</p>

    <div class="flex flex-col items-center sm:mt-[24px] mt-[12px]">
      <div class="team-members-container">
        @for (member of teamMembers.slice(currentSet * 5, (currentSet + 1) * 5); track member.id; let i = $index) {
          <div class="team-member-image" (click)="toggleInfo(i)" [class.active]="activeIndex === i">
            <img [@fadeTransition] [ngSrc]="member.image" alt="{{ member.name }}" fill>
            <div class="member-info">
              <span class="member-name">{{ member.name }}</span>
              <span class="member-role">{{ member.role }}</span>
            </div>
          </div>
        }
      </div>
    </div>

  </div>

  <p class="main-title bottom-title" dir="rtl">נסו את <strong class="font-bold">פלטפורמת ההפצה
    המתקדמת</strong> שלנו
    עכשיו!</p>
  <button (click)="checkLogin()" class="bottom-button main-button-design gradient-button">
    התחילו להפיץ עכשיו
  </button>
  <sbz-footer></sbz-footer>
</div>
