<div [@fadeTransition] class="w-full flex justify-center">
  <div [ngClass]="'lg:!w-[845px] sm:!w-[677px]'"
       class="white-container sm:w-[896px] sm:max-w-none w-[90%] max-w-[896px] !rounded-[30.3px] md:!rounded-[42px]">
    <div class="white-container-wrapper">
      @if (verificationStatus === 'pending') {
        <div>
          <p>מאמתים את כתובת האימייל שלך, אנא המתן...</p>
        </div>
      }

      @if (verificationStatus === 'success') {
        <div class="text-center relative">
          <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light">
            <strong class="font-bold">האימייל אומת בהצלחה!</strong>
          </p>
          <br/>
          <p class="font-prop-normal font-prop-normal-desc text-center text-[14px] leading-[25.5px] font-poppins">
            כתובת האימייל שלך אומתה. כעת תוכל להתחבר.
          </p>
          <div class="flex justify-center space-x-2">
            <div class="parent-container">
              <a (click)="navigateToLogin()" class="btn btn-primary">
                <button
                    class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[312px] lg:!w-[312px] sm:!w-[312px] !w-[312px] main-button-design mx-auto"
                    type="submit">
                  סיום הרשמה
                </button>
              </a>
            </div>
          </div>
        </div>
      }

      @if (verificationStatus === 'error') {
        <div class="text-center relative">
          <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light">
            <strong class="font-bold">אימות האימייל נכשל</strong>
          </p>
          <br/>
          <p class="font-prop-normal font-prop-normal-desc text-center text-[14px] leading-[25.5px] font-poppins">
            {{ errorMessage }}
          </p>
          <div class="flex justify-center space-x-2">
            <div class="parent-container">
              <a (click)="navigateToLogin()" routerLink="/resend-verification" class="btn btn-primary">
                <button
                    class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[201px] main-button-design mx-auto"
                    type="button">
                  שלח מחדש אימייל לאימות
                </button>
              </a>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</div>