import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {finalize, firstValueFrom} from 'rxjs';
import {LoadingService} from './loading.service';

declare var Stripe: any;

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private elements: any;
  private stripe = Stripe(environment.stripeKey);

  constructor(private httpClient: HttpClient,
              private loadingService: LoadingService) {
    this.elements = this.stripe.elements();
  }

  createCardElement(style: any) {
    return this.elements.create('card', {style});
  }

  createCardNumberElement(style?: any) {
    const cardNumber = this.elements.getElement('cardNumber');
    if (cardNumber) {
      return cardNumber;
    }
    return this.elements.create('cardNumber', {style: style});
  }

  createCardExpiryElement(style?: any) {
    const cardExpiry = this.elements.getElement('cardExpiry');
    if (cardExpiry) {
      return cardExpiry;
    }
    return this.elements.create('cardExpiry', {style: style});
  }

  createCardCvcElement(style?: any) {
    const cardCvc = this.elements.getElement('cardCvc');
    if (cardCvc) {
      return cardCvc;
    }
    return this.elements.create('cardCvc', {style: style});
  }

  async createNewPaymentMethod(cardNumber: any) {
    const {paymentMethod, error} = await this.stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });
    return {paymentMethod, error};
  }

  async createPaymentMethod(cardNumber: any, packageId: string = '', couponId: string = '', price = '', product = '') {
    this.loadingService.toggleLoading(true);
    const {paymentMethod, error} = await this.stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
    });

    const url = `${environment.baseUrl}/subscriptions/create-subscription`;
    return firstValueFrom(this.httpClient.post(url, {packageId, paymentMethodId: paymentMethod.id, couponId, price, product}).pipe(
      finalize(() => {
        this.loadingService.toggleLoading(false);
      })
    ));
  }
}
