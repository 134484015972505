<div [@fadeTransition] class="flex flex-col items-center h-full">
  <div class="text-center 2xl:mt-[83px]">
    <p class="font-prop-normal main-title">Welcome to <strong class="font-bold">Dstro</strong></p>
    <p class="font-prop-normal sub-title">What would you like to do today?</p>
  </div>

  <div class="flex items-center justify-center flex-row 2xl:mt-[93px]">
    <button class="main-button-design black-button 2xl:me-[39px] 2xl:w-[290px]" routerLink="/distribute">Create a song
      with US
    </button>
    <span class="small-title">or</span>
    <button class="main-button-design gradient-button 2xl:ms-[39px] 2xl:w-[290px]" routerLink="/distribute">Distribute
      your song
    </button>
  </div>
</div>
