<div *ngIf="(user$ | async) && (userNeedToVerifyEmail$ | async)" [@fadeTransition] class="w-full h-full flex justify-center">
  <div class="white-container sm:w-[896px] sm:max-w-none w-[90%] max-w-[896px] !rounded-[30.3px] md:!rounded-[42px]" dir="rtl">
    <div *ngIf="(user$ | async)?.email" class="text-center relative">
      <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong class="font-bold">תודה רבה!</strong></p>
      <br />
      <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins">
        אנא בדוק את תיבת הדואר שלך.
        {{ (user$ | async)?.email }}
      </p>
      <br />
    </div>
    <div *ngIf="!(user$ | async)?.email" class="white-container-wrapper">
      <div class="text-center relative">
        <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong class="font-bold">נא הזן את כתובת האימייל שלך</strong></p>
        <br />
        <section class="flex-grow w-full ps-[18px] m-[10px] mt-0 sm:mt-0 custom-login-container">
          <form [formGroup]="emailForm" (ngSubmit)="submitEmail()">
            <div class="input-container">
              <input type="email" placeholder="הזן את כתובת האימייל שלך" formControlName="email" class="input-style" />
            </div>
            <div class="flex justify-center space-x-2">
              <div class="parent-container">
                <button type="submit"
                        class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[301px] lg:!w-[301px] sm:!w-[301px] !w-[301px] main-button-design mx-auto">
                  אמת
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(user$ | async) && (userNeedToRegister$ | async) && !(userNeedToEnterNewPassword$ | async)" [@fadeTransition] class="w-full h-full flex justify-center">
  <sbz-register [activeStep]="activeStep" [submitForm]="submitForm" (forceStepChange)="changeActiveStep($event)"></sbz-register>
</div>

<div *ngIf="!(user$ | async) || !(userNeedToVerifyEmail$ | async) && !(userNeedToRegister$ | async)" [@fadeTransition] class="w-full h-full flex justify-center">
  <div class="white-container sm:w-[896px] sm:max-w-none w-[90%] max-w-[896px] !rounded-[30.3px] md:!rounded-[42px]" dir="rtl">
    <div class="text-center relative">
      <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light">בואו <strong class="font-bold">להתחבר</strong></p>
      <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins">
        אין לך חשבון?
        <span class="text-[#d13e69] font-poppins cursor-pointer" (click)="showRegisterForm()">הירשם עכשיו</span>.
      </p>
    </div>
    <div class="white-container-wrapper">
      <div class="content-container grid grid-cols-1 lg:grid-cols-[1fr_auto_1fr] gap-6">
        <!-- Social Login Section -->
        <section class="flex-grow w-full ps-[18px] m-[10px] custom-social-login-container">
          <button class="social-button google-button mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]" (click)="loginWithGoogle()">
            Continue with Google
          </button>
          <button class="social-button facebook-button mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]" (click)="loginWithFacebook()">
            Continue with Facebook
          </button>
        </section>
        
        <!-- Divider -->
        <div class="vertical-divider hidden lg:flex justify-center items-center w-[50px]">
          <div class="w-[2px] h-full bg-gray-300"></div>
          <span class="mx-4 text-sm text-gray-500 self-center" style="background: #fff; padding: 20px;">או</span>
          <div class="w-[2px] h-full bg-gray-300"></div>
        </div>

        <!-- Custom Email Login Section -->
        <section class="flex-grow w-full ps-[18px] m-[10px] mt-0 sm:mt-0 custom-login-container">
          <form *ngIf="activeForm === 'login'" (ngSubmit)="submitEmailLogin()">
            <input type="email" id="email" [(ngModel)]="email" name="email" placeholder="אימייל" class="input-style mt-1 block w-full"/>
            <input type="password" id="password" [(ngModel)]="password" name="password" placeholder="סיסמה" class="input-style mt-4"/>
            <p class="font-prop-normal text-left text-[14px] font-poppins mt-2 ps-[18px]">
              שכחת סיסמה?
              <span class="text-[#d13e69] cursor-pointer" (click)="forgotPassword()">לחץ כאן</span>.
            </p>
            <button type="submit" class="main-button-design black-button w-full mt-6">התחבר</button>
          </form>

          <!-- Register Form -->
          <form *ngIf="activeForm === 'register'" (ngSubmit)="submitRegisterForm()">
            <input type="email" id="registerEmail" [(ngModel)]="registerEmail" name="registerEmail" placeholder="אימייל" class="input-style mt-1 block w-full"/>
            <input type="password" id="registerPassword" [(ngModel)]="registerPassword" name="registerPassword" placeholder="סיסמה" class="input-style mt-4"/>
            <button type="submit" class="main-button-design black-button w-full mt-6">הרשמה</button>
          </form>
          <p class="font-prop-normal text-center mt-4" *ngIf="activeForm === 'register'">
            יש לך חשבון?
            <span class="text-[#d13e69] cursor-pointer" (click)="showLoginForm()">התחבר כאן</span>.
          </p>
        </section>
      </div>
    </div>
  </div>
</div>