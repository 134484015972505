<p class="text-center text-[#252931] title">בואו נסיים את ההרשמה - <strong>זה חינם</strong></p>
<form (ngSubmit)="onSubmit()" [formGroup]="form"
      [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}">
  @switch (activeStep) {
    @case (1) {
      <div class="reg-input-container">
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="text" placeholder="Full Name" formControlName="fullName"
                 class="input-style placeholder-[#25293166]">
          @if (getErrorForField('fullName')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">שם מלא נדרש</span>
            </div>
          }
        </div>
        <div class="active-one-container mb-6 lg:mb-0">
          <input #phoneField type="text" placeholder="Phone Number" formControlName="phone"
                 class="input-style placeholder-[#25293166]">
          @if (getErrorForField('phone')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נדרש מספר טלפון תקין</span>
            </div>
          }
        </div>
      </div>
      <div class="reg-input-container mb-[13px] lg:mb-0">
        <div class="active-one-container mb-6 lg:mb-0">
          <input type="email" placeholder="Email" formControlName="email" class="input-style placeholder-[#25293166]">
          @if (getErrorForField('email')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">נדרש אימייל תקין</span>
            </div>
          }
        </div>
        <div class="active-one-container">
          <!-- <select formControlName="role" class="input-style">
            <option value="">What’s your main music focus?</option>
            @for (validRole of validRoles; track validRole) {
              <option [value]="validRole" (change)="setOption(validRole)">{{ validRole }}</option>
            }
          </select> -->
          <sbz-multi-select
              [options]="validRoles"
              (selectedChange)="onSelectionChange($event)">
          </sbz-multi-select>
          @if (getErrorForField('userRoles')) {
            <div class="invalid-input-container">
              <div class="relative w-[14px] h-[13px]">
                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>
              </div>
              <span class="invalid-input">אנא בחר את המיקוד המוזיקלי שלך</span>
            </div>
          }
        </div>
      </div>
    }
    @case (2) {
      <!-- First Line of Inputs -->
      <div class="reg-input-container justify-between">
        <!-- Youtube Channel Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-youtube.webp" alt="ערוץ יוטיוב"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="ערוץ יוטיוב" formControlName="youtubeChannel"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('youtubeChannel')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
        <!-- Instagram URL Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-instagram.webp" alt="קישור לאינסטגרם"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="קישור לאינסטגרם" formControlName="instagramUrl"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('instagramUrl')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
      </div>

      <!-- Second Line of Inputs -->
      <div class="reg-input-container justify-between mb-[13px] lg:mb-0">
        <!-- Spotify Artist Page Input -->
        <div class="active-two-container mb-6 lg:mb-0">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-spotify.webp" alt="עמוד אומן בספוטיפיי"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="עמוד אומן בספוטיפיי" formControlName="spotifyArtistPage"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('spotifyArtistPage')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
        <!-- Apple Music Profile Input -->
        <div class="active-two-container">
          <div class="absolute inset-y-0 start-0 flex items-center ps-[20px] pointer-events-none">
            <img src="assets/images/inputs/input-applemusic.webp" alt="פרופיל אפל מיוזיק"
                 class="!w-[35px] !h-[35px] sm:!w-[75px] sm:!h-[75px] lg:!w-[40px] lg:!h-[40px]">
          </div>
          <input type="text" placeholder="פרופיל אפל מיוזיק" formControlName="appleMusicProfile"
                 class="input-style !ps-[50px] sm:!ps-[95px] lg:!ps-[60px]">
          <!--          @if (getErrorForField('appleMusicProfile')) {-->
          <!--            <div class="invalid-input-container">-->
          <!--              <div class="relative w-[14px] h-[13px]">-->
          <!--                <img ngSrc="../../../assets/images/inputs/input-invalid.webp" alt="invalid" fill>-->
          <!--              </div>-->
          <!--              <span class="invalid-input">Please enter a valid link</span>-->
          <!--            </div>-->
          <!--          }-->
        </div>
      </div>
    }
    @case (3) {
      <div class="reg-input-container justify-between">
        <div class="mb-6 px-2 lg:w-[168px] lg:me-[25px] w-full">
          <sbz-file-upload fileType="image/*" [userImage]="userImage"
                           (base64ImageCreated)="base64ImageCreated($event)"></sbz-file-upload>
        </div>
        <div class="mb-6 px-2 lg:w-[535px] w-full">
           <textarea placeholder="ספר לנו על עצמך" formControlName="description"
                     class="input-style placeholder-[#25293166] lg:!h-[155px] sm:!h-[371px] !h-[172px] sm:pt-[40px] lg:pt-0"
                     style="resize: none;"></textarea>
        </div>
      </div>
    }
  }
</form>
